import { authHandler } from './authHandler';
import { handleResponse } from './handleResponse';

export const refreshToken = async (oldUrl, oldRequestOptions,setUser, navigate) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({refresh: JSON.parse(window.localStorage.getItem('refresh'))})
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/auth/token/refresh/`, requestOptions)
        .then(r => r.json())
        .then(data => {
            if(data.access) {
                localStorage.setItem('access', JSON.stringify(data.access));
                if (oldRequestOptions.headers && oldRequestOptions.headers.Authorization) {
                    oldRequestOptions.headers.Authorization = authHandler(setUser,navigate)
                }
                return fetch(oldUrl, oldRequestOptions).then().then(r => handleResponse(r, oldRequestOptions,setUser));
            } else if(data.detail === 'Token jest niepoprawny lub wygasł'){
                return logOut(setUser, navigate);
            }
            return data;
        });
};
export const logOut = (setUser, navigate) => {
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
    setUser(null)
    navigate('/login');
};
