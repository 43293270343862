import styled from "styled-components";

export const Container = styled.div`
    width: 250px;
    background-color: #1D1D20;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: 600ms;
    z-index: 5555;
    padding-top:85px;
`;

export const Wrapper = styled.div`
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom:85px;
`;

export const SearchButton = styled.button`
    height: 41px;
    width: 210px;
    background-color: #b12583;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 5px;
    font-family: Poppins;
    margin-top: 60px;
    outline: none;
    border: none;
    margin-bottom: 40px;

    &:hover {
        background: #D34CA7;
    }
`;

export const Navigation = styled.nav`
    width: 100%;
    padding-top:65px;
`;

export const List = styled.ul`
    width: 100%;
`;

export const ListElement = styled.li`
    width: 100%;
    display: flex;
    margin-bottom:40px;
    justify-content: flex-end;
    align-items: center;
`;

export const Link = styled.a`
    width: 100%;
    display: block;
    text-align:right;
    padding-right:25px;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight:400;
    color: #FFFFFF;
`;

export const CloseIcon = styled.div`
    width: 20px;
    height: 20px;
    position: relative;
    // margin-top: 20px;
`;

export const Closeline = styled.div`
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: white;
    transform: rotate(45deg);
    transform-origin: center;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: white;
        transform: rotate(-90deg);
        transform-origin: center;
      }
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    top: 30px;
    right: 19px;
`;
export const Call = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    align-items:flex-end;
    padding-right:25px;
`
export const Number = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    align-items:flex-end;
    margin-bottom:20px;
`
export const Text = styled.span`
    font-size:16px;
    font-family:'Poppins', sans-serif;
    font-weight:300;
    color:#fff;
    margin-bottom:10px;
`
export const NumberText = styled.span`
    font-size:24px;
    font-family:'Poppins', sans-serif;
    font-weight:700;
    color:#fff;
`
export const CallButton = styled.button`
    width:130px;
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:100px;
    background-color:#26C176;
    font-size:20px;
    font-family: 'Poppins', sans-serif;
    font-weight:300;
    color:#fff;
    border:none;
    outline:none;
`
