

const handleCloseMenuSidebar = () => {
    const pageWrapper = document.getElementById('pageWrapper')
    pageWrapper.classList.toggle('pageWrapper_move')

    const slider_menu = document.getElementById('slider_menu')
    slider_menu.classList.toggle('active_slider')

    const empty_background = document.getElementById('empty_backgound')
    empty_background.classList.toggle('empty_background_active')

    if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'unset';
    } else {
        document.body.style.overflow = 'hidden';
    }
}

export default handleCloseMenuSidebar