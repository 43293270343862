const RoutesPaths = {
	Home: {
		path: "/",
		route: () => "/",
	},
	Register: {
		path: "/register",
		route: () => "/",
	},
	Login: {
		path: "/login",
		route: () => "/",
	},
	UserProfile: {
		path: "/owner-profile",
		route: () => "/",
	},
	DogProfile: {
		path: "/dog-profile",
		route: () => "/",
	},
	RegisterID: {
		path: "/register-id",
		route: () => "/",
	},
	Success: {
		path: "/register-success",
		route: () => "/",
	},
	Payment: {
		path: "/payments",
		route: () => "/",
	},
	Vet: {
		path: "/vet",
		route: () => "/",
	},
	Owner: {
		path: "/owner",
		route: () => "/",
	},
	About: {
		path: "/about",
		route: () => "/",
	},
	Policy: {
		path: "/polityka",
	},
	Rules:{
		path: "/regulamin",
	}

};

export default RoutesPaths;
