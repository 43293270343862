import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/settings/reset.css'
import './components/settings/authCodeInput.css'
import './components/settings/sliderAnimation.css'
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

