import React from 'react'
import * as S from "./styles"
import handleCloseMenuSidebar from '../../helpers/handleCloseMenuSidebar'

const HamburgerIcon = () => {



    return (
        <>
            <div id='empty_backgound' className='empty_background' onClick={() => handleCloseMenuSidebar()} />
            <S.HamburgerIcon onClick={handleCloseMenuSidebar} >
                <S.HamburgerElement></S.HamburgerElement>
                <S.HamburgerElement center></S.HamburgerElement>
                <S.HamburgerElement></S.HamburgerElement>
            </S.HamburgerIcon>
        </>


    )
}

export default HamburgerIcon
