import styled from "styled-components";
import breakpoints from "../../settings/breakpoints";

export const Container = styled.div`
	width: 100%;
	background: #090B06;
    padding: 35px 30px;
	@media screen and (min-width: 835px) {
        padding: 35px 30px;
        height:300px;
        }
`;

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	@media screen and (min-width: 835px) {
        height: 100%;
		flex-direction: row;
        justify-content: space-between;
        }
`;


export const Title = styled.h5`
	margin-top: 30px;
	color: #FFF;
	font-family: Poppins;
	font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: 0px;
`;

export const ContactTitle = styled.h5`
margin-top: 50px;
color: #FFF;
font-family: Poppins;
font-size: 24px;
line-height: 32px;
font-weight: 400;
letter-spacing: 0px;
@media screen and (min-width: ${breakpoints.sm}px) {
	margin-top: 0;
	}
`;

export const IconsWrapper = styled.div`
margin-top: 50px;
margin-left: 50px;
`;

export const Icon = styled.img`
`;

export const SingleIconWrapper = styled.div`
margin-bottom: 12px;
display: flex;
flex-direction: row;
`;

export const ContactText = styled.p`
	color: #FFFFFF;
	font-size: 13px;
	
`;

export const Column = styled.div`
    display:flex;
    flex-direction: column;
    @media screen and (min-width: 800px) {
        margin-left: 10px;
    }
    @media screen and (min-width: 885px) {
        margin-left: 20px;
    }
    @media screen and (min-width: 1040px) {
        margin-left: 50px;
    }
	@media screen and (min-width: 1310px) {
		margin-left: 105px;
		}
   
`;

export const Text = styled.span`
    font-size:16px;
    font-family:'Poppins', sans-serif;
    font-weight:300;
    color:#fff;
    margin-bottom:20px;
`
export const Number = styled.span`
    font-size:24px;
    font-family:'Poppins', sans-serif;
    font-weight:600;
    color:#fff;
`
export const AppStore = styled.img`
    width:150px;
    height:${props => props.google ? "45px" : "50px"};
    cursor:pointer;
    @media(max-width:835px){
        margin-top:20px;
    }
`
export const HeaderLogo = styled.span`
    font-size:28px;
    font-family:'Poppins', sans-serif;
    font-weight:800;
    color:#fff;
    cursor: pointer;
    display:flex;
    flex-wrap:wrap;
    align-content: center;
    margin-bottom:20px;
`
export const Right = styled.div`
    display:flex;
    align-items: flex-start ;
    justify-content: space-between;
    @media(max-width: 835px){
        flex-direction: column;
    }
`
export const Link = styled.a`
    cursor: pointer;
    color:#fff;
    text-decoration:none;
    &:hover{
        color:#26C176;
        
    }
`
