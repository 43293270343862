import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from "../../../App";
import { useLocation, useNavigate } from 'react-router-dom'
import * as S from "./styles"
import HamburgerIcon from './HamburgerIcon'

const DISPLAY_ENUM = {
    NONE: 'none',
    STICKY: 'sticky',
    ABSOLUTE: 'absolute'
}

const Header = () => {
    const { user } = useContext(UserContext);
    const [stickyHeader, setStickyHeader] = useState(DISPLAY_ENUM.ABSOLUTE)

    const navigate = useNavigate();

    const handleClickLink = (url) => {
        navigate(url)
    }
    let location = useLocation();

    useEffect(() => {
        const handleScroll = (event) => {
            const scrolledDistance = window.pageYOffset;
            if (scrolledDistance < 82) {
                setStickyHeader(DISPLAY_ENUM.ABSOLUTE)
            }
            if (scrolledDistance > 80 && scrolledDistance < 100) {
                setStickyHeader(DISPLAY_ENUM.NONE)
            }
            if (scrolledDistance > 100) {
                setStickyHeader(DISPLAY_ENUM.STICKY)
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <S.Container display={stickyHeader === DISPLAY_ENUM.NONE} opacity={stickyHeader !== DISPLAY_ENUM.ABSOLUTE} primary={stickyHeader === DISPLAY_ENUM.STICKY}>
                <S.Wrapper>
                    <S.HeaderLogo onClick={() => navigate("/")}>DOG<S.Highlight>id</S.Highlight></S.HeaderLogo>
                    <S.HeaderNavigation>
                        <S.Menu>
                            <S.MenuElement><S.Link>Wyszukaj DOGid</S.Link></S.MenuElement>
                            <S.MenuElement><S.Link href={'https://www.facebook.com/identyfikatorydogid/'}>Facebook</S.Link></S.MenuElement>
                            {/*<S.MenuElement onClick={() => handleClickLink(RoutesPaths.Home.path)}><S.Link active={location.pathname === RoutesPaths.Home.path}>Home</S.Link></S.MenuElement>*/}
                            {/*<S.MenuElement onClick={() => handleClickLink(RoutesPaths.Vet.path)}><S.Link active={location.pathname === RoutesPaths.Vet.path}>Dla weterynarza</S.Link></S.MenuElement>*/}
                            {/*<S.MenuElement onClick={() => handleClickLink(RoutesPaths.Owner.path)}><S.Link active={location.pathname === RoutesPaths.Owner.path}>Dla właściciela</S.Link></S.MenuElement>*/}
                            {/*<S.MenuElement onClick={() => handleClickLink(RoutesPaths.About.path)}><S.Link active={location.pathname === RoutesPaths.About.path}>O projekcie</S.Link></S.MenuElement>*/}
                        </S.Menu>
                        <S.Right>
                            <S.MenuElement number><S.Text>Infolinia</S.Text>{' '}<S.Number>914 222 222</S.Number></S.MenuElement>
                            <S.MenuElement><S.Link>Zarejestruj</S.Link></S.MenuElement>
                        </S.Right>
                    </S.HeaderNavigation>

                    <HamburgerIcon />
                </S.Wrapper>

            </S.Container>
        </>



    );
}

export default Header
