import {Routes, Route} from 'react-router-dom';
import React, {useState} from 'react';
import routerList from './routerList';
import RouteWrapper from './components/wrapper/RouteWrapper';

export const routes = (
    <Routes>
        {routerList.map(({path, component}) => {
            return (
                <Route
                    key={`${path}`}
                    path={path}
                    element={<RouteWrapper>{component}</RouteWrapper>}
                />
            );
        })}
        <Route
            path='*'
            element={<p>Not found page</p>}
        />
    </Routes>
)
