import React from "react";
import RoutesPaths from "./RoutesPaths.js";

const HomePage = React.lazy(() => import("./components/pages/Home/Home"));
const UserProfilePage = React.lazy(() =>
	import("./components/pages/UserProfile/UserProfilePage")
);
const LoginPage = React.lazy(() => import("./components/pages/Login/Login"));
const RegisterPage = React.lazy(() =>
	import("./components/pages/Register/Register")
);
const DogProfile = React.lazy(() =>
	import("./components/pages/DogProfile/DogProfile")
);
const RegisterID = React.lazy(() =>
	import("./components/pages/RegisterID/RegisterID")
);
const Success = React.lazy(() =>
	import("./components/pages/Success/Success")
);
const Payment = React.lazy(() =>
	import("./components/pages/Payment/Payment")
);
const VetPage = React.lazy(() =>
	import("./components/pages/Vet/Vet")
);
const OwnerPage = React.lazy(() =>
	import("./components/pages/Owner/Owner")
);
const AboutPage = React.lazy(() =>
	import("./components/pages/About/About.js")
);
const Policy = React.lazy(() =>
	import("./components/pages/Policy/Policy.js")
);
const Rules = React.lazy(() =>
	import("./components/pages/Rules/Rules.js")
);
const routerList = [
	{
		path: RoutesPaths.Home.path,
		component: <HomePage />,
	},
	{
		path: RoutesPaths.Register.path,
		component: <RegisterPage />,
	},
	{
		path: RoutesPaths.Login.path,
		component: <LoginPage />,
	},
	{
		path: RoutesPaths.UserProfile.path,
		component: <UserProfilePage />,
	},
	{
		path: RoutesPaths.DogProfile.path,
		component: <DogProfile />,
	},
	{
		path: RoutesPaths.RegisterID.path,
		component: <RegisterID />,
	},
	{
		path: RoutesPaths.Success.path,
		component: <Success />,
	},
	{
		path: RoutesPaths.Payment.path,
		component: <Payment />,
	},
	{
		path: RoutesPaths.Vet.path,
		component: <VetPage />,
	},
	{
		path: RoutesPaths.Owner.path,
		component: <OwnerPage />,
	},
	{
		path: RoutesPaths.About.path,
		component: <AboutPage />,
	},
	{
		path: RoutesPaths.Policy.path,
		component: <Policy />,
	},
	{
		path: RoutesPaths.Rules.path,
		component: <Rules />,
	},
];

export default routerList;
