import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import PageWrapper from "./components/wrapper/PageWrapper";
import { createGlobalStyle } from "styled-components";
import { routes } from "./routes";
import { createContext } from "react";
import { logOut } from "./components/hooks/authentication.service";
import SliderMenu from "./components/elements/SliderMenu/SliderMenu";

export const UserContext = createContext(null);
export const DogContext = createContext(null);
const GlobalStyles = createGlobalStyle`
  :root {
    --large: 1440px;
    --medium: 1024px;
    --small: 768px;
  }
  #root{
    position: relative;
    overflow-x: hidden;
  }
  html {
    font-size: 10px;

    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;
const App = () => {
  const [user, setUser] = useState(null);
  const [dog, setDog] = useState(null);
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const dogValue = useMemo(() => ({ dog, setDog }), [dog, setDog]);

  useEffect(() => {
    if (
      localStorage.getItem("user") !== null &&
      localStorage.getItem("user") !== "undefined"
    ) {
      setUser({
        access: JSON.parse(localStorage.getItem("access")),
        refresh: JSON.parse(localStorage.getItem("refresh")),
        user_id: JSON.parse(localStorage.getItem("user")).user_id,
        user_profile_id: JSON.parse(localStorage.getItem("user"))
          .user_profile_id,
        photographer_profile_id: JSON.parse(localStorage.getItem("user"))
          .photographer_profile_id,
      });
    } else if (
      localStorage.getItem("refresh") !== null &&
      localStorage.getItem("refresh") !== "undefined"
    ) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          refresh: JSON.parse(window.localStorage.getItem("refresh")),
        }),
      };
      return fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/token/refresh/`,
        requestOptions
      )
        .then((r) => r.json())
        .then((data) => {
          if (data.access) {
            localStorage.setItem("access", JSON.stringify(data.access));
            localStorage.setItem(
              "user",
              JSON.stringify({
                user_id: data.user_id,
                user_profile_id: data.user_profile_id,
                photographer_profile_id: data.photographer_profile_id,
              })
            );
            return null;
          } else if (data.detail === "Token is invalid or expired") {
            return logOut(setUser);
          }
        });
    }
  }, []);
  return (
    <>
      <GlobalStyles />

      <UserContext.Provider value={userValue}>
        <DogContext.Provider value={dogValue}>
          <BrowserRouter>
            <PageWrapper>{routes}</PageWrapper>
            <SliderMenu />
          </BrowserRouter>
        </DogContext.Provider>
      </UserContext.Provider>
    </>
  );
};

export default App;
