import React from 'react'
import * as S from './styles'

const CloseIcon = ({ handleClose }) => {
    return (
        <S.CloseIconWrapper onClick={() => handleClose()}>
            <S.CloseIcon>
                <S.Closeline />
                <S.Closeline />
            </S.CloseIcon>
        </S.CloseIconWrapper>

    )
}

export default CloseIcon