import React from 'react'
import * as S from './Footer.styles'
import * as H from '../Header/styles'
import AppStore from '../../../assets/AppStores/app-store.png'
import GooglePlay from '../../../assets/AppStores/google-play.png'
import { useLocation, useNavigate } from 'react-router-dom'
const Footer = () => {
    const navigate = useNavigate();
    return (
        <S.Container>
            <S.Wrapper>
                <S.Column>
                    <S.HeaderLogo onClick={() => navigate("/")}>DOG<H.Highlight>id</H.Highlight></S.HeaderLogo>
                    <S.Text>
                        DOGid <br />Niemcewicza 26 <br /> 71-520 Szczecin
                    </S.Text>
                </S.Column>
                <S.Right>
                <S.Column>
                    <S.Text><S.Link href={`/regulamin`}>Regulamin serwisu</S.Link></S.Text>
                    <S.Text><S.Link href={`/polityka`}>Polityka prywatności</S.Link></S.Text>
                </S.Column>
                <S.Column>
                    <S.Text>Znalazłeś psa?</S.Text>
                    <S.Number>914 222 222</S.Number>
                </S.Column>
                <S.Column>
                    <S.AppStore src={AppStore}/>
                </S.Column>
                <S.Column>
                    <S.AppStore src={GooglePlay} google />
                </S.Column>
                </S.Right>
            </S.Wrapper>
        </S.Container>
    )
}

export default Footer
