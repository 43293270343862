import React from 'react'
import Footer from '../elements/Footer/Footer'
import Header from '../elements/Header/Header'

const PageWrapper = (props) => {
  return (
    <div id={'pageWrapper'}>
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default PageWrapper