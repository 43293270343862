import React from 'react'
import * as S from './styles'
import CloseIcon from './CloseIcon'
import handleCloseMenuSidebar from '../../helpers/handleCloseMenuSidebar'

const menuList = [
    { name: "Wyszukaj DOGid", url: "" },
    { name: "Zarejestruj", url: "" },
]

const SliderMenu = () => {
    return (
        <S.Container id='slider_menu'>
            <S.Wrapper>
                <CloseIcon handleClose={() => handleCloseMenuSidebar()} />
                <S.Navigation>
                    <S.List>
                        {menuList.map((element, index) => {
                            return <S.ListElement key={index} ><S.Link href={element.url}>{element.name}</S.Link></S.ListElement>
                        })}
                    </S.List>
                </S.Navigation>
                <S.Call>
                    <S.Number>
                        <S.Text>Znalazłeś psa?</S.Text>
                        <S.NumberText>914 222 222</S.NumberText>
                    </S.Number>
                    <S.CallButton onClick={() => window.location.href='tel:914222222'}>Zadzwoń!</S.CallButton>
                </S.Call>
            </S.Wrapper>
        </S.Container>
    )
}

export default SliderMenu
